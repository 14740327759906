<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.5834 14.4167C15.9167 14.75 15.9167 15.25 15.5834 15.5834C15.4167 15.75 15.2501 15.8334 15.0001 15.8334C14.7501 15.8334 14.5834 15.75 14.4167 15.5834L10.0001 11.1667L5.58341 15.5834C5.41675 15.75 5.25008 15.8334 5.00008 15.8334C4.75008 15.8334 4.58341 15.75 4.41675 15.5834C4.08341 15.25 4.08341 14.75 4.41675 14.4167L8.83341 10L4.41675 5.58335C4.08341 5.25002 4.08341 4.75002 4.41675 4.41669C4.75008 4.08335 5.25008 4.08335 5.58341 4.41669L10.0001 8.83335L14.4167 4.41669C14.7501 4.08335 15.2501 4.08335 15.5834 4.41669C15.9167 4.75002 15.9167 5.25002 15.5834 5.58335L11.1667 10L15.5834 14.4167Z"
      fill="black"
    />
    <mask
      id="mask0_3072_4433"
      style="mask-type:luminance"
      maskUnits="userSpaceOnUse"
      x="4"
      y="4"
      width="12"
      height="12"
    >
      <path
        d="M15.5834 14.4167C15.9167 14.75 15.9167 15.25 15.5834 15.5834C15.4167 15.75 15.2501 15.8334 15.0001 15.8334C14.7501 15.8334 14.5834 15.75 14.4167 15.5834L10.0001 11.1667L5.58341 15.5834C5.41675 15.75 5.25008 15.8334 5.00008 15.8334C4.75008 15.8334 4.58341 15.75 4.41675 15.5834C4.08341 15.25 4.08341 14.75 4.41675 14.4167L8.83341 10L4.41675 5.58335C4.08341 5.25002 4.08341 4.75002 4.41675 4.41669C4.75008 4.08335 5.25008 4.08335 5.58341 4.41669L10.0001 8.83335L14.4167 4.41669C14.7501 4.08335 15.2501 4.08335 15.5834 4.41669C15.9167 4.75002 15.9167 5.25002 15.5834 5.58335L11.1667 10L15.5834 14.4167Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_3072_4433)">
      <rect
        width="20"
        height="20"
        fill="#5E5873"
      />
    </g>
  </svg>
</template>
<script>
export default {
  name: 'CloseIcon',
}
</script>
