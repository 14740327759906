<template>
  <div>
    <b-modal
      id="add_a_user"
      modal-class="modal-add-user"
      header-bg-variant="white"
      centered
      header-class="d-flex align-items-center"
      hide-footer
      hide-header-close
      size="md"
      @close="onClickCancel"
      @hide="trackHideModal"
    >
      <button
        class="close-icon"
        @click="$bvModal.hide('add_a_user')"
      >
        <close-icon />
      </button>
      <div class="modal-add-user-body mb-2">
        <div class="modal-add-user-header">
          Add new retailer
        </div>
        <div class="sub-title">
          {{
            isFormDisabled
              ? 'This retailer exists in our platform. Please choose from the dropdown'
              : 'Complete all fields'
          }}
        </div>
        <div class="mt-2">
          <validation-observer
            v-if="!isFormDisabled"
            ref="addRetailerForm"
            #default="{ invalid }"
          >
            <b-form
              class="mt-2"
              @submit.prevent="onSubmit(false)"
            >
              <div>
                <validation-provider
                  #default="{ errors }"
                  name="Store Name"
                  rules="required|min:3"
                >
                  <b-form-group
                    label="Store Name"
                    label-class="pb-0"
                  >
                    <b-form-input
                      v-model="form.entityName"
                      placeholder="Store Name"
                      :state="errors.length > 0 ? false : null"
                      :readonly="isFormReadonly"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
                <validation-provider
                  #default="{ errors }"
                  name="First Name"
                  rules="required|min:3"
                >
                  <b-form-group
                    label="First Name"
                    label-class="pb-0"
                  >
                    <b-form-input
                      v-model="form.firstName"
                      placeholder="First Name"
                      :state="errors.length > 0 ? false : null"
                      :readonly="isFormReadonly"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
                <validation-provider
                  #default="{ errors }"
                  name="Last Name"
                  rules="required|min:1"
                >
                  <b-form-group
                    label="Last Name"
                    label-class="pb-0"
                  >
                    <b-form-input
                      v-model="form.lastName"
                      placeholder="Last Name"
                      :state="errors.length > 0 ? false : null"
                      :readonly="isFormReadonly"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-group
                    label="Email"
                    label-class="pb-0"
                  >
                    <b-form-input
                      v-model="form.email"
                      placeholder="Email"
                      :state="errors.length > 0 ? false : null"
                      :readonly="isFormReadonly"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
                <validation-provider>
                  <b-form-group
                    label="Whatsapp Number"
                    label-for="account-phone"
                  >
                    <vue-tel-input
                      v-model="phoneNumber"
                      :input-options="inputOptions"
                      mode="international"
                      name="phone"
                      :class="isFormReadonly ? 'tel-input-disabled' : ''"
                      :disabled="isFormReadonly"
                      :auto-default-country="autoDefaultCountry"
                      :default-country="defaultCountry"
                      @input="onInputPhoneNumber"
                    />
                    <small
                      v-if="isNotValidPhoneNumber"
                      class="text-danger"
                    >Please enter the valid phone number</small>
                  </b-form-group>
                </validation-provider>
                <template v-if="countries.length">
                  <validation-provider
                    #default="{ errors }"
                    name="Country"
                    rules="required"
                  >
                    <b-form-group
                      label="Country"
                      label-class="pb-0"
                    >
                      <v-select
                        v-model="form.country"
                        :options="countries"
                        placeholder="Country"
                        :disabled="isFormReadonly"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </template>
              </div>
              <div class="w-100 d-flex justify-content-end mt-2 mb-1">
                <b-button
                  variant="outline-primary"
                  :disabled="isCreating"
                  @click="onClickCancel"
                >
                  Cancel
                </b-button>
                <b-button
                  class="ml-1"
                  type="submit"
                  variant="custom-primary"
                  :disabled="invalid || isNotValidPhoneNumber || isCreating"
                >
                  <span> Add retailer </span>
                  <feather-icon
                    v-if="isCreating"
                    class="ml-1"
                    icon="LoaderIcon"
                  />
                </b-button>
              </div>
            </b-form>
          </validation-observer>
          <div v-if="isFormDisabled">
            <b-form-group
              label-for="exclude-countries"
              label="Exclude Countries"
            >
              <template #label>
                <div class="d-flex justify-content-between">
                  <span class="d-flex">
                    Select Retailers
                  </span>
                  <span class="badge badge-pill badge-light-primary elements-center">
                    {{ retailers.length }} Retailer{{ retailers.length > 1 ? 's' : '' }} selected
                  </span>
                </div>
              </template>
              <b-dropdown
                v-click-outside="hideCountriesDropdown"
                block
                variant="none"
                class="countries-dropdown"
                :class="{ show: showRetailers }"
                :menu-class="`w-100 retailers-menu scroll-bar ${showRetailers ? 'show' : ''}`"
                toggle-class="p-0"
                toggle-tag="div"
                dropdown
                no-caret
                @show.prevent
                @hide.prevent
              >
                <template #button-content>
                  <v-select
                    v-model="retailers"
                    :options="allRetailers"
                    multiple
                    :close-on-select="false"
                    :deselect-from-dropdown="true"
                    :no-drop="true"
                    class="restricted-countries-dropdown"
                    placeholder="Select Retailers"
                    label="entityName"
                    @search:focus="showRetailers = true"
                    @search="searchRetailers"
                  />
                </template>
                <b-dropdown-item
                  v-for="retailer in allRetailersLocal"
                  :key="`ac_${retailer._id}`"
                  :active="retailers.map((_) => _._id).includes(retailer._id)"
                  :disabled="retailer.isAdded"
                  @click="handleSelectRetailer(retailer)"
                >
                  {{ getRetailerStatus(retailer) }} {{ retailer.entityName }}
                </b-dropdown-item>
              </b-dropdown>
            </b-form-group>
            <div class="w-100 d-flex justify-content-end mt-2 mb-1">
              <b-button
                variant="outline-primary"
                :disabled="isCreating"
                @click="onClickCancel"
              >
                Cancel
              </b-button>
              <b-button
                class="ml-1"
                type="submit"
                variant="custom-primary"
                :disabled="!retailers.length || isCreating"
                @click="onSubmit(true)"
              >
                <span> Add retailer </span>
                <feather-icon
                  v-if="isCreating"
                  class="ml-1"
                  icon="LoaderIcon"
                />
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import analytics from '@/@core/utils/analytics'
import { apiToastError, apiToastSuccess } from '@/@core/utils/toast'
import constants from '@/constants'
import { GET_COUNTRIES } from '@/store/modules/common.module'
import { ADD_RETALIER, INVITE_RETAILER, INVITE_RETAILER_FROM_LEADAI } from '@/store/modules/connections.module'
import { constants as c } from '@kingpin-global/kingpin-utils-frontend'
import { email, min, required } from '@validations'
import CloseIcon from '@/@core/assets/svg-components/CloseIcon.vue'
import {
  BButton,
  BDropdown,
  BDropdownItem,
  BForm,
  BFormGroup,
  BFormInput,
  BModal,
  VBModal,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import countryCodeData from '@/assets/data/countryCode.json'

const { ROLES } = c

export default {
  name: 'AddUserModal',
  components: {
    BModal,
    BButton,
    BFormGroup,
    BFormInput,
    BForm,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    FeatherIcon,
    BDropdown,
    BDropdownItem,
    CloseIcon,
  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
      required,
      email,
      min,
      form: {
        entityName: '',
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        countryCode: '',
        country: '',
      },
      phoneNumber: '',
      autoDefaultCountry: true,
      defaultCountry: '',
      isFormReadonly: false,
      fromLeadAI: false,
      isCreating: false,
      allRetailers: [],
      allRetailersLocal: [],
      retailers: [],
      showRetailers: false,
      isFormDisabled: false,
      isValidPhoneNumber: false,
      inputOptions: {
        id: 'whatsapp_number',
        placeholder: 'Enter whatsapp number',
      },
      countries: [],
    }
  },
  computed: {
    brandUser() {
      return this.$store.getters.profile
    },
    isNotValidPhoneNumber() {
      return this.form?.phone && !this.isValidPhoneNumber
    },
  },
  created() {
    this.loadCountries()
  },
  emits: ['load-connections'],
  methods: {
    onInputPhoneNumber(number, phoneObject) {
      this.form.phone = number && phoneObject.nationalNumber
      this.form.countryCode = `+${phoneObject.countryCallingCode}`
      this.isValidPhoneNumber = !!phoneObject.valid
    },
    updateFormData(leadDetails) {
      this.form.entityName = leadDetails?.storeName
      this.form.firstName = leadDetails.firstName || ''
      this.form.lastName = leadDetails.lastName || ''
      this.form.email = leadDetails.email || ''
      this.form.country = leadDetails.country || ''
      this.form.leadId = leadDetails?.id || ''
    },
    openModal(leadDetails) {
      this.updateFormData(leadDetails)
      if (this.form.country !== '') {
        this.updateCountryCode(this.form.country)
      }
      this.$bvModal.show('add_a_user')
      this.fromLeadAI = true
      this.isFormReadonly = leadDetails?.isFormReadonly ?? true
      this.setPhoneData(leadDetails)
    },
    trackHideModal() {
      this.$emit('on-add-retailer-close', {
        ...this.getPayloadData(),
        leadId: this.form.leadId,
      })
    },
    setPhoneData(leadDetails) {
      this.phoneNumber = leadDetails?.phoneNumber || ''

      if (leadDetails?.phoneObject) {
        this.onInputPhoneNumber(leadDetails.phoneNumber, leadDetails.phoneObject)
      }
    },
    updateCountryCode(countryName) {
      const countryData = countryCodeData.find(country => country.name === countryName)

      if (countryData) {
        this.autoDefaultCountry = false
        this.defaultCountry = countryData.code
      }
    },
    getRetailerStatus(retailer) {
      return retailer.isAdded ? '(added)' : ''
    },
    async onSubmit(shouldProceed = false) {
      let payload
      try {
        this.isCreating = true
        let actionType
        if (shouldProceed) {
          actionType = ADD_RETALIER
          payload = { storeIds: this.getRetailersPayload(), isFromLeadAI: this.fromLeadAI }
        } else if (this.fromLeadAI) {
          actionType = INVITE_RETAILER_FROM_LEADAI
          payload = this.getPayloadData()
        } else {
          actionType = INVITE_RETAILER
          payload = this.getPayloadData()
        }
        this.$emit('on-add-retailer', {
          ...payload,
          leadId: this.form.leadId,
        })
        await this.$store.dispatch(actionType, { payload })
        this.handleSuccess(payload)
      } catch (err) {
        this.handleError(payload, err)
      } finally {
        this.isCreating = false
      }
    },
    getRetailersPayload() {
        return this.retailers.map(retailer => retailer._id)
    },

    getParams(shouldUpdateExistingUser) {
      const params = {}
      if (shouldUpdateExistingUser) {
        params.shouldUpdateExistingUser = true
      }
      return params
    },

    handleSuccess(payload) {
      this.$emit('on-add-retailer-success', {
        ...payload,
        leadId: this.form.leadId,
      })
      apiToastSuccess('Retailer created successfully!', 'CoffeeIcon')
      this.onClickCancel()
      this.trackAnalytics()
      this.$emit('load-connections')
    },
    handleError(payload, err) {
      if (err?.response?.status === 422) {
        const allRetailers = err.response?.data?.data?.stores
          this.allRetailers = allRetailers?.sort((a, b) => a.isAdded - b.isAdded)
          this.searchRetailers()
          this.isFormDisabled = true
        return
      }
      this.$emit('on-add-retailer-failed', {
        ...payload,
        leadId: this.form.leadId,
      }, err)
      apiToastError(err, 'TimesIcon')
    },
    trackAnalytics() {
      analytics.track(constants.TRACKS.ACTIONS.BRAND_INVITE_RETAILER_USING_WHATSAPP, {
        brandId: this.brandUser?._id,
        retailerId: this.retailers.map(retailer => retailer._id),
        phone: this.form.phone,
        countryCode: this.form.countryCode,
        email: this.form.email,
        fromLeadAI: this.fromLeadAI,
      })
    },
    resetForm() {
      this.form = {
        entityName: '',
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        countryCode: '',
        country: '',
      }
    },
    getPayloadData() {
      const payload = {
        entityName: this.form.entityName,
        firstName: this.form.firstName,
        lastName: this.form.lastName,
        email: this.form.email,
        country: this.form.country,
        role: ROLES.RETAILER,
        isSelfManaged: true,
        brandId: this.brandUser?._id,
        isFromLeadAI: this.fromLeadAI,
      }
      if (this.form.phone) {
        payload.phone = this.form.phone
        payload.countryCode = this.form.countryCode
      }
      return payload
    },
    onClickCancel() {
      this.$emit('on-add-retailer-close', {
        ...this.getPayloadData(),
        leadId: this.form.leadId,
      })
      this.resetForm()
      this.isFormDisabled = false
      this.retailers = []
      this.allRetailers = []
      this.selectAllRetailers = false
      this.$bvModal.hide('add_a_user')
    },
    loadCountries() {
      this.$store.dispatch(GET_COUNTRIES).then(res => {
        this.countries = res?.data?.data?.COUNTRIES || []
      })
      .catch(err => {
        apiToastError(err)
      })
    },
    hideCountriesDropdown() {
      this.showRetailers = false
    },
    newRetailers() {
      return this.allRetailers.find(retailer => !retailer.isAdded)
    },
    handleSelectRetailer(retailer = '') {
      if (retailer?.isAdded) return
      if (retailer) {
        if (this.retailers.map(_retailer => _retailer._id).includes(retailer._id)) {
          this.retailers = this.retailers.filter(
            _retailer => _retailer._id !== retailer._id,
          )
        } else {
          this.retailers.push(retailer)
        }
      }
      this.showRetailers = false
    },
    searchRetailers(searchingText = '') {
      if (searchingText) {
        this.allRetailersLocal = this.allRetailers.filter(retailer => retailer.entityName.toLowerCase()
          .indexOf(searchingText.toLowerCase()) !== -1)
      } else {
        this.allRetailersLocal = this.allRetailers
      }
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/bootstrap-extended/include'; // Bootstrap includes
@import '~@core/scss/base/components/include';

.close-icon {
  position: absolute;
  height: 35px;
  width: 35px;
  background-color: white;
  top: -36px;
  right: -15px;
  border: none;
  border-radius: 5px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  transition: all 0.23s ease 0.1s;
  &:hover {
    transform: translate(-3px, 3px);
  }
}

.vs__search {
  &::placeholder {
    color: $text-muted !important;
  }
}

.retailers-menu {
  max-height: 320px;
  overflow: auto;
}

.vue-tel-input {
  height: 2.714rem;
  border: 1px solid #E0E0E0;

  .vti__dropdown-list {
    z-index: 200;
  }
}

.tel-input-disabled > * {
  cursor: not-allowed !important;
}

.tel-input-disabled > div.vti__dropdown:hover {
  background-color: unset !important;
}
</style>
